import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useInfiniteQuery } from '@tanstack/react-query'
import Loader from '@elements/Loader'
import Notice from '@elements/Notice'
import AppService from '@services/AppService'
import Card from './Card'

export default function Column({ name, settings, users }) {
  const [loading, setLoading] = useState(true);
  const params = new URLSearchParams(location.search)
  params.set('traits', '[board]')
  params.set('status', name)

  function fetchJobs({ pageParam }) {
    params.set('page', pageParam)
    return AppService.jobs.collection(params.toString())
  }

  const jobsQuery = useInfiniteQuery({
    queryKey: ['jobs', { status: name }],
    queryFn: fetchJobs,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage.meta.next_page,
  })

  function renderLoadMore() {
    if (jobsQuery.isFetchingNextPage) {
      return (
        <div className="load-more">
          <Loader />
        </div>
      )
    }

    return (
      <>
        <div className="load-more">
          <button
            onClick={() => jobsQuery.fetchNextPage()}
            disabled={jobsQuery.isFetchingNextPage}
          >
            Load More
          </button>
        </div>
      </>
    )
  }

  function renderCards() {
    if (jobsQuery.status === 'error') {
      return <Notice message={jobsQuery.error.message} type={jobsQuery.status} />
    }

    if (loading) {
      return <Loader />
    }

    return (
      <>
        {jobsQuery.data.pages.map((group, i) => (
          <Fragment key={i}>
            {group.jobs.map(job => (
              <Card key={`job-${job.id}`} initialJob={job} settings={settings} users={users}/>
            ))}
          </Fragment>
        ))}

        {jobsQuery.hasNextPage && renderLoadMore()}
      </>
    )
  }

  useEffect(() => {
    if (jobsQuery.status === 'success') {
      setLoading(false)
    }
  }, [jobsQuery.status])

  return (
    <div className="board-column">
      <div className="board-column-header">
        {name}
      </div>
      <div className="board-column-cards">
        {renderCards()}
      </div>
    </div>
  )
}

Column.propTypes = {
  name: PropTypes.string.isRequired,
  settings: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object).isRequired
}
