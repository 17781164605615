import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import styles from './DatePickerElement.module.scss'

export default function DatePickerElement({ date, field, onChange }) {
  const selectedDate = date ? moment(date).toDate() : null

  function handeChange(selected) {
    return onChange(field, moment(selected).format('YYYY-MM-DD'))
  }

  return (
    <div className={styles.wrapper}>
      <DatePicker
        selected={selectedDate}
        onChange={handeChange}
        dateFormat="dd MMM yyyy"
        placeholderText="Select a date"
        isClearable
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
      />
    </div>
  )
}

DatePickerElement.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}
