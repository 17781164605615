import React, { useEffect, useState } from 'react';
import Select from '@atlaskit/select';

export default function Multiselect({
  initialOptions = [], defaultValues = [], placeholder = 'Select', field, onChange
}) {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  function transformOptions(data) {
    return data.map(item => ({
      value: item.id,
      label: item.name,
    }))
  }

  function handleChange(selected) {
    onChange(field, selected.map(obj => obj.value));
  }

  useEffect(() => {
    const transformed = transformOptions(initialOptions)
    setOptions(transformed)

    if (defaultValues.length > 0) {
      const selected = transformed.filter(option => defaultValues.includes(option.value));
      setSelectedOptions(selected)
    }
  }, [])

  function renderSelect() {
    if (!options.length > 0) {
      return null
    }

    return (
      <>
        <Select
          inputId="indicators-clear"
          className="multi-select"
          classNamePrefix="react-select"
          closeMenuOnSelect={false}
          appearance="subtle"
          defaultValue={selectedOptions}
          isMulti
          options={options}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </>
    )
  }

  return (
    <>
      {renderSelect()}
    </>
  )
}
