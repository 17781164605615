import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { capitalize, map } from 'lodash';
import DatePickerElement from '@elements/DatePickerElement'
import Multiselect from '@elements/Multiselect'
import AppService from '@services/AppService'

export default function Card({ initialJob, settings, users = [] }) {
  const [job, setJob] = useState(initialJob)
  const [status, setStatus] = useState('')
  const prevJobRef = useRef(initialJob)

  const jobMutation = useMutation({
    mutationFn: jobData => AppService.jobs.update(initialJob.id, { job: jobData }),
    onError: (message) => {
      setStatus('error')
      window.toastr.error(message)
    },
    onSuccess: () => {
      setStatus('success')
    }
  })

  const handleUpdate = useCallback(
    debounce((jobData) => {
      setStatus('')
      jobMutation.mutate(jobData)
    }, 2000),
    [jobMutation]
  )

  function handleChange(field, value) {
    setJob(prev => ({
      ...prev,
      [field]: value,
    }));
  }

  function capitalizeEach(string) {
    return map(string.split('_'), capitalize).join(' ');
  }

  function renderDates() {
    if (!settings.date_fields || settings.date_fields.length === 0) {
      return null
    }

    return (
      <>
        {settings.date_fields.map(field => (
          <div className="board-card-date" key={field}>
          <span>
            {capitalizeEach(field)}
          </span>
            <DatePickerElement
              date={job[field]}
              field={field}
              onChange={handleChange}
            />
          </div>
        ))}
      </>
    )
  }

  useEffect(() => {
    if (prevJobRef.current !== job) {
      handleUpdate(job)
      prevJobRef.current = job
    }
    return () => {
      handleUpdate.cancel()
    }
  }, [job, handleUpdate])

  return (
    <div className={classNames('board-card', 'status-bar', status)}>
      <div className="board-card-header">
        <div className="board-card-name">
          <input
            type="text"
            value={job.name}
            title={job.name}
            onChange={e => handleChange('name', e.target.value)}
          />
          <p>{job.name}</p>
        </div>
        <div className="board-card-id">
          <a href={AppService.jobs.resourcePath(job.id)} className="link">
            #{job.id}
          </a>
        </div>
      </div>

      <div className="mb-4 pl-2">
        {renderDates()}

        <div className="board-card-members">
          <span>Requesters</span>
          <Multiselect
            initialOptions={users}
            defaultValues={job.requester_ids}
            field="requester_ids"
            onChange={handleChange}
            placeholder="Select Requesters"
          />
        </div>
        <div className="board-card-members">
          <span>Assignees</span>
          <Multiselect
            initialOptions={users}
            defaultValues={job.assignee_ids}
            field="assignee_ids"
            onChange={handleChange}
            placeholder="Select Asignees"
          />
        </div>
      </div>
    </div>
  )
}

Card.propTypes = {
  initialJob: PropTypes.object.isRequired,
  settings: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object).isRequired
}
