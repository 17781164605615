import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import Loader from '@elements/Loader'
import Notice from '@elements/Notice'
import AppService from '@services/AppService'
import Column from './components/Column'

export default function KanbanBoardApp({ columns, settings = {} }) {
  const boardRef = useRef(null)
  const params = new URLSearchParams({ traits: '[board]' })

  const usersQuery = useQuery({
    queryKey: ['users'],
    queryFn: () => AppService.users.collection(params.toString())
  });

  function handleResize() {
    if (boardRef.current) {
      const margin = 50
      boardRef.current.style.height = `${window.innerHeight - boardRef.current.getBoundingClientRect().top - margin}px`
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function renderColumns() {
    if (usersQuery.status === 'error') {
      return <Notice message={usersQuery.error.message} type={usersQuery.status} />
    }

    if (usersQuery.status === 'pending') {
      return <Loader />
    }

    return columns.map(columnName => (
      <Column
        key={columnName}
        name={columnName}
        users={usersQuery.data}
        settings={settings}
      />
    ))
  }

  return (
    <div className="board" ref={boardRef}>
      {renderColumns()}
    </div>
  )
}

KanbanBoardApp.propTypes = {
  columns: PropTypes.array.isRequired,
  settings: PropTypes.object
}
